import React from "react";
import { Layout, Tooltip } from "antd";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//@ts-ignore
import { useTranslation } from "react-i18next";
import "./style.scss";
import Logo from "assets/LogoSystem/min_logo.png";
import bussinessReport from "assets/images/png/business-report.png";
import processPng from "assets/images/png/process.png";
import appDevelopPng from "assets/images/png/app-development.png";
import rechargePng from "assets/images/png/recharge.png";
import worldWebsitePng from "assets/images/png/world-wide-web.png";
import lifeStylePng from "assets/images/png/lifestyle.png";
import trolleyPng from "assets/images/png/trolley.png";
import chatPng from "assets/images/png/chat.png";
import contactPng from "assets/images/png/contact.png";

import {
  NEW_ORDER,
  SERVICES,
  ORDERS,
  ADD_FUNDS,
  API,
  MASS_ORDER,
  AFFILIATES,
  TICKETS,
  CONTACT,
} from "pages/routes/route.constant";

const { Sider } = Layout;

interface NavItemProps {
  to: string;
  icon: string;
  label: string;
  collapsed: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label, collapsed }) => (
  <Tooltip placement="right" title={collapsed ? label : null}>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-4 p-3.5 ${collapsed ? "pl-3" : "w-[88%] pl-6"} rounded-lg text-[14px] font-semibold transition-colors duration-300 ${
          isActive ? "bg-[#E6F4F1] text-[#2CA58D]" : "text-[#ffffff]"
        } hover:bg-[#E6F4F1] hover:text-[#2CA58D]`
      }
    >
      <img src={icon} width={25} height={25} alt="" />
      {!collapsed && <span>{label}</span>}
    </NavLink>
  </Tooltip>
);

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  seoData?: any;
}

const SideBar: React.FC<SideBarProps> = ({
  collapsed,
  setCollapsed,
  seoData,
}) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const navItemsData = [
    { to: NEW_ORDER, icon: trolleyPng, label: t("newOrder") },
    { to: SERVICES, icon: bussinessReport, label: t("services") },
    { to: ORDERS, icon: processPng, label: t("orders") },
    { to: ADD_FUNDS, icon: appDevelopPng, label: t("addFunds") },
    { to: API, icon: worldWebsitePng, label: t("api") },
    { to: AFFILIATES, icon: rechargePng, label: t("affiliates") },
    { to: TICKETS, icon: chatPng, label: t("tickets") },
    { to: CONTACT, icon: contactPng, label: t("contact") },
    { to: MASS_ORDER, icon: lifeStylePng, label: t("massOrder") },
  ];

  return (
    <Sider
      onCollapse={(value) => setCollapsed(value)}
      width={
        collapsed ? (isTabletOrMobile ? 0 : 72) : isTabletOrMobile ? 256 : 256
      }
      theme="light"
      breakpoint="xl"
      collapsed={collapsed}
      collapsedWidth={isTabletOrMobile ? 0 : 72}
      className={`fixed z-[999] h-screen select-none pb-12`}
      style={{ position: "fixed" }}
    >
      {!collapsed && isTabletOrMobile && (
        <Link className="my-2 flex w-full items-center" to="/">
          <div className="ml-2 flex w-full items-center sm:ml-0">
            <img src={Logo} width={35} height={35} alt="" />
            <h1 className="text-primary ml-2 text-nowrap text-center text-lg font-semibold sm:ml-2 sm:mt-1 sm:text-xl lg:text-[24px]">
              {seoData?.title || "Min SMM"}
            </h1>
          </div>
        </Link>
      )}
      {!collapsed && (
        <div className="w-full">
          <div className={`w-full p-2 ${collapsed ? "pl-1.5" : ""}`}>
            <h1
              className={`${collapsed ? "ml-0" : "ml-2"} text-[16px] font-medium text-[#364152]`}
            >
              {/* {t("client")} */}
            </h1>
          </div>
        </div>
      )}
      <div
        className={`flex flex-col items-center justify-center gap-1 ${collapsed ? "mt-4" : ""}`}
      >
        {navItemsData.map((item) => (
          <NavItem
            key={item.to}
            to={item.to}
            icon={item.icon}
            label={item.label}
            collapsed={collapsed}
          />
        ))}
      </div>
    </Sider>
  );
};

export default SideBar;
